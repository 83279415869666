//--------------------------------------------------
//Quest Sources
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { TTSButton } from '../GeneralComponents';

import { FaArrowLeft, FaArrowRight, FaCamera } from "react-icons/fa";

import questionMark from '../../Images/objective-question-mark.svg'

const Sources = ({ sources, togglePhaseButton, backgroundImage, type, category, currentPhasePos, message, AddSnapshotSource, AddTeachMoreSource }) => {


    const [content, setContent] = useState(null);
    const [currentImagePos, setCurrentImagePos] = useState(0);
    const [currentImage, setCurrentImage] = useState(null);
    const [currentCitation, setCurrentCitation] = useState('Image Citation');
    const [currentType, setCurrentType] = useState('Text');

    const [disableRightArrow, setDisableRightArrow] = useState('');
    const [disableLeftArrow, setDisableLeftArrow] = useState('arrows-grey');

    const [adaptation, setAdaptation] = useState('');
    const [adaptationCitation, setAdaptationCitation] = useState('');
    const [toggle, setToggle] = useState(false);

    const [objectivePopup, setObjectivePopup] = useState('hidden');


    const [objective, setObjective] = useState('')

    const [isSnapshot, setIsSnapshot] = useState('hidden');
    const [isTeachMore, setIsTeachMore] = useState('hidden');
    const [snapshotSource, setSnapshotSource] = useState('');


    useEffect(() => {
        setIsTeachMore('hidden')
        setIsSnapshot('hidden');
        setContent(sources);
        setToggle(false);
        setCurrentImage(null);
        setCurrentImagePos(0)
        resetArrows();
        if (!sources || sources?.length < 1) {
            setCurrentCitation('');
            togglePhaseButton(true);
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
        }
        else {
            setCurrentType(sources[0].type)
            const temp = ['Text', 'Video']
            if (temp.includes(sources[0].type)) {
                setCurrentImage(sources[0].file);
            }
            else {
                setCurrentImage(userActions.GetFile(formateFileName(sources[0].file)));
            }
            setCurrentCitation(sources[0].citation)
            setCurrentImagePos(0);
        }
        console.log("sources ", sources)
        if (sources?.length < 2) {
            setAdaptation('');
            setAdaptationCitation('');
            togglePhaseButton(true);
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
        } else if (sources) {
            setAdaptation(sources[1]?.type == 'Adaptation' ? sources[1]?.file : '');
            setAdaptationCitation(sources[1]?.type == 'Adaptation' ? sources[1]?.citation : '');
        }

        console.log("message: ", message)

        SetPhaseObjective(category)
    }, [sources]);

    const moveImage = (direction) => {

        if (direction > 0 && disableRightArrow == 'arrows-grey') {
            return;
        }
        if (direction < 0 && disableLeftArrow == 'arrows-grey') {
            return;
        }

        setAdaptation('');
        setAdaptationCitation('');
        if (content.length <= currentImagePos + direction) {
            return;
        }
        else if (currentImagePos + direction < 0) {
            return;
        }
        setDisableLeftArrow(currentImagePos + direction <= 0 ? 'arrows-grey' : '');
        if (content.length <= currentImagePos + direction + 1 || (content[content.length - 1].type == 'Adaptation' && currentImagePos + direction == content.length - 2)) {
            setDisableRightArrow('arrows-grey')
        }
        else {
            setDisableRightArrow('')
        }
        setCurrentType(content[currentImagePos + direction].type)

        if (content[currentImagePos + direction].type == 'Adaptation') {
            moveImage(direction * 2);
            return;
        }

        const temp = ['Text', 'Video']
        if (temp.includes(content[currentImagePos + direction].type)) {
            setCurrentImage(content[currentImagePos + direction].file);
            if (content[currentImagePos + (direction + 1)] && content[currentImagePos + (direction + 1)].type == 'Adaptation') {
                setAdaptation(content[currentImagePos + (direction + 1)].file)
                setAdaptationCitation(content[currentImagePos + (direction + 1)].citation);
                //Spawn button to toggle
            }
        }
        else {
            setCurrentImage(userActions.GetFile(formateFileName(content[currentImagePos + direction].file)));
        }

        setCurrentCitation(content[currentImagePos + direction].citation)
        setCurrentImagePos(currentImagePos + direction);
        if (content.length <= currentImagePos + direction + 1) {
            togglePhaseButton(true);
        }
    }

    const resetArrows = () => {
        setDisableLeftArrow('arrows-grey');
        setDisableRightArrow('');
    }

    const formateFileName = (file) => {
        let temp = file.replace('#', '%23');
        return temp;
    }

    const SetPhaseObjective = (category) => {
        switch (category) {
            case 'three_type':
                setObjective('Analyze the following sources and write 1 ' + type.split(', ')[0] + ', ' + type.split(', ')[1] + ', and ' + type.split(', ')[2] + ' for each in your Idaho Travelogue. Use proper sentences to continue. Each sentence will grant 1 gem, so write as many as you can! Press enter to type on a new line for the next source.');
                break;
            case 'two_type':
                setObjective('Analyze the following sources and write 1 ' + type.split(' and ')[0] + ' and ' + type.split(' and ')[1] + ' for each in your Idaho Travelogue. Use proper sentences to continue. Each sentence will grant 1 gem, so write as many as you can! Press enter to type on a new line for the next source.');
                break;
            case 'story':
                setObjective("In your Idaho Travelogue, use what you have learned from the watchman to write a short fictional story from the perspective of someone shown in one of these photographs.")
                break;
            case 'letter':
                setObjective("Now, carefully examine the following documents that highlight some of the problems Lake Coeur d’Alene is facing today. In your Idaho Travelogue, write a letter to the editor of the Coeur d’Alene Press with your ideas about what should be done so the area can be enjoyed for many years to come. In your letter, include key information about the history of this area, current issues, and what different people and groups might do to help.")
                break;
            case 'poem':
                setObjective("Now, read or listen to four quotes about the event. Why do you think there were different perspectives? Do you think it could have been possible for the leaders to understand each other's points of view? Select 5-10 words from each quote that stand out as being very important to the speaker’s perspective. Write these in your Idaho Travelogue using a comma to separate them. Then combine and rearrange the 20 - 40 words to create a poem that embodies all of the four individual’s ideas. You are welcome to add punctuation and a few of your own words to help your poem make sense. Remember to also give your poem a title. ")
                break;
            case 'traits':
                setObjective('Write down as many words or sentences that you feel are traits of the topic. See below for the topic. Each word awards 1 gem, each sentence awards 4.')
                break;
            case 'phrase':
                setObjective(' Select a Notable Quote, Phrase, or Fact from the sources, type it in your Travelogue. Then write a sentence explaining why you chose this quote, phrase, or fact. Do this at least 1 time, up to 2 times for 8 gems each.')
                break;
            case 'questions':
                setObjective("Respond to the statement(s) and/or question(s) found below or in the above message.")
                break;
            case 'details':
                setObjective("Write a detail you've found for each of the following sources in the 'Details' column found in your Travelogue. Also write names of individuals or groups found in the 'Perspectives' column. ")
                break;
            case 'events':
                setObjective("Write an event that happened within each source. Then answer the following for each source: How did responsibility for the water and land change? ")
                break;
            case 'word_bank':
                setObjective("Make sure to use complete sentences when writing an interesting fact! Bonus gems for using words in the Bank.")
                break;
            case 'snapshot':
                setObjective(`Cycle through all the sources analyzed in this Quest. Find a source you resonate with and click the Snapshot Button. Once you click the button, answer the questions about the source. 
                    
                    What did you choose to take a picture of? 
Why did you choose this item? 
What do you want to remember about this artifact? 
`)
                setIsSnapshot('')
                break;
            case 'teach_me_more':
                setObjective(`Cycle through all the sources analyzed in this Quest. Find a source you resonate with and click the Snapshot Button. Once you click the button, answer the questions about the source. 
                        
                        What did you choose to take a picture of? 
    Why did you choose this item? 
    What do you want to remember about this artifact? 
    `)
                setIsTeachMore('')
                break;
            default:
                setObjective('Read the following sources before continuing to the next phase.\n Feel free to write notes in your Idaho Travelogue!');
                break;
        }
    }

    const SnapSource = (target) => {

        if (category !== 'snapshot') {
            setIsSnapshot('hidden');
            return;
        }

        if (snapshotSource) {
            setDisableLeftArrow('');
            setDisableRightArrow('');
            setSnapshotSource('');
        }
        else {
            setDisableLeftArrow('arrows-grey');
            setDisableRightArrow('arrows-grey');
            setSnapshotSource(currentImage);
            AddSnapshotSource(currentImage);
        }

    }

    const AddTeachMore = (target) => {

        if (category !== 'teach_me_more') {
            setIsTeachMore('hidden');
            return;
        }

        AddTeachMoreSource(currentImage);

    }

    //console.log(content)

    return (
        <Fragment>
            <TTSButton isActive={message != "" ? true : false} utterance={message}/>
            <div className='full quest-source-container' style={{ backgroundImage: `url(${userActions.GetFile(backgroundImage)})`, backgroundSize: 'cover', backgroundColor: '#FFE7DC', maxHeight: '100%' }} id='sources'>
                <h2 className='grid-col-12' style={{ overflowY: 'scroll', margin: '2%', fontSize: '1.25em', textAlign: 'center', backgroundColor: 'white', borderRadius: '0.5em', padding: '1%', color: 'black', boxShadow: 'inset 0px 9px 13px -8px rgba(0,0,0,0.5)', top: '0px' }}>{message}</h2>
                <section className='grid-col-12 panel-shadow' style={{ display: 'grid', gridTemplateColumns: '1fr 8fr 1fr', gridTemplateRows: '1fr', backgroundColor: 'white', margin: '2% 2% 4% 2%', position: 'relative', maxHeight: '100%' }}>
                    <FaArrowLeft className={`side-arrows ${disableLeftArrow}`} onClick={() => {moveImage(-1); setToggle(false)}} />
                    <TTSButton isActive={currentType == 'Text' ? true : false} utterance={adaptation ? (toggle ? adaptation : currentImage) : currentImage}/>
                    <div style={{ backgroundColor: '#ebebeb', height: '80%', color: 'black', margin: 'auto', display: 'grid', gridTemplateRows: '1fr auto', width: '100%', borderRadius: '0.5em', padding: '1%' }}>
                        {currentType == 'Text' ? 
                            currentPhasePos == 0 ? 
                                <p style={{ margin: '2% 0%', maxHeight: '80%', overflowY: 'scroll' }}>{toggle ? adaptation : currentImage}</p> 
                                : <p style={{ margin: '2% 0%', maxHeight: '80%', overflowY: 'scroll' }}><span style={{ fontSize: '2em', fontWeight: 'bold' }}>Source:</span><br></br>{toggle ? adaptation : currentImage}</p> 
                            : currentType == 'Video' ? 
                                <iframe src={currentImage} style={{ height: '100%', width: '100%' }} controls allow={'autoplay'}></iframe> 
                                : <img src={currentImage} style={{ margin: '1% auto 0% auto', maxWidth: '100%', height: '-webkit-fill-available', maxHeight: '400px' }}></img>}
                            
                        {currentPhasePos == 0 ? 
                            <></>
                            : <p style={{ fontSize: '1.1vi' }}><span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Citation:</span><br></br> {toggle ? adaptationCitation : currentCitation}</p>
                        }
                        <div className='stat inline-flex' style={{ display: adaptation != '' ? 'flex' : 'none', position: 'absolute', justifySelf: 'end', padding: '1%' }}>
                            <label className="switch">
                                <input type="checkbox" onChange={(e) => setToggle(e.target.checked)} checked={toggle ? true : false}></input>
                                <span className="slider round"></span>
                            </label>
                            <span>Adapted Version</span>
                        </div>
                    </div>

                    <div className='stat inline-flex' style={{ display: 'flex', position: 'absolute', justifySelf: 'end'}}>
                        <p style={{ fontSize: '2em', color: 'black', fontWeight: 'bold', margin: 'auto 1%' }}>Objective</p>
                        <img className='objective-mark' src={questionMark} onClick={() => setObjectivePopup('')}></img>
                    </div>
                    <FaArrowRight className={`side-arrows ${disableRightArrow}`} onClick={() => {moveImage(1); setToggle(false);}} />
                    <div className={isSnapshot} style={{ display: 'flex', position: 'absolute', justifySelf: 'end', alignSelf: 'end', marginTop: '5%', marginRight: '2%', width: 'auto', cursor: 'pointer' }}>
                        <p style={{ alignSelf: 'center', color: 'black', fontWeight: 'bold', fontSize: '1.6vi', margin: '1%', whiteSpace: 'nowrap' }}>Take Snapshot</p>
                        <FaCamera style={snapshotSource ? { color: 'var(--purple)' } : { color: '2e2e2e' }} size={'4vi'} onClick={(e) => SnapSource(e.target)} />
                    </div>
                    <div className={isTeachMore} style={{ display: 'flex', position: 'absolute', justifySelf: 'end', alignSelf: 'end', margin: '3%', width: 'auto', cursor: 'pointer' }}>
                        <p style={{ alignSelf: 'center', color: 'black', fontWeight: 'bold', fontSize: '1.5em', margin: 'auto 1%', whiteSpace: 'nowrap' }}>Add Source</p>
                        <FaCamera style={{ color: '2e2e2e' }} size={'4em'} onClick={(e) => AddTeachMore(e.target)} />
                    </div>
                </section>
                <section className={`bannerBackground ${objectivePopup}`}>
                    <section className={`bannerPopup`}>
                        <div className='header'>Phase Objective</div>
                        <div className='content'>{objective}</div>
                        <div className='footer'>
                            <button className='purple' style={{ width: '50%', margin: 'auto', justifySelf: 'center' }} onClick={() => setObjectivePopup('hidden')}>Close</button>
                        </div>
                    </section>
                </section>
            </div>
        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Sources);
export { connection as Sources };