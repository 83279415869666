//--------------------------------------------------
//Phase Component
//--------------------------------------------------

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';
import { useIdleTimeout } from '../../Actions/useIdleTimeout';


const Travelogue = ({ phases, currentPhase, currentPhasePos, goToPhase, answers, progress, session, MovetoNextPhase, displayButton }) => {

    const params = new URLSearchParams(new URL(window.location.href).search);
    const quest = params.get("quest");
    const token = localStorage.getItem('token');

    const [content, setPhases] = useState([]);
    const [travelogueAnswers, setTravelogueAnswers] = useState({})
    const [currentText, setCurrentText] = useState('');
    const [error, setError] = useState('');
    const [textBoxes, setTextBoxes] = useState([])

    const [displayPhaseButton, setPhaseButton] = useState('hidden');

    const isIdle = useIdleTimeout(3000, () => {
        //5 seconds of idle
        //Save the travelogue to server after this idle timer
        userActions.UpdateTravelogue(token, quest, { answers: answers, progress: progress }, (data) => {
        })
    });


    useEffect(() => {
        setTravelogueAnswers({ answers: answers ? answers : {}, progress: progress ? progress : {} });
        setPhaseButton(currentPhase.category == 'read' ? true : false)
        let temp = answers ? answers : {};
        let progress_temp = progress ? progress : {};
        if (!progress_temp) {
            progress_temp = {};
        }
        if (Object.keys(temp).length < 1) {
            Object.keys(phases).map((value, key) => {
                temp[key] = {};
                progress_temp[key] = 0;
                switch (phases[value].category) {
                    case 'two_type':
                        temp[key][phases[value].type.split(' and ')[0]] = '';
                        temp[key][phases[value].type.split(' and ')[1]] = '';
                        break;
                    case 'three_type':
                        temp[key][phases[value].type.split(', ')[0]] = '';
                        temp[key][phases[value].type.split(', ')[1]] = '';
                        temp[key][phases[value].type.split(', ')[2]] = '';
                        break;
                    case 'poem':
                        temp[key]['Words'] = '';
                        temp[key]['Poem'] = '';
                        break;
                    case 'phrase':
                        temp[key]['Quote, Phrase, Fact'] = '';
                        temp[key]['Reason'] = '';
                        break;
                    case 'details':
                        temp[key]['Detail'] = '';
                        temp[key]['Perspective'] = '';
                        break;
                    case 'snapshot':
                        temp[key]['Source'] = '';
                        temp[key]['Answer'] = '';
                        break;
                    case 'teach_me_more':
                        temp[key]['Source'] = '';
                        temp[key]['Reason'] = '';
                        break;
                        case 'word_bank':
                            temp[key]['Bank'] = 'Suffrage, Suffragette, Amendment, Declaration, Barriers, Prejudice, Indigenous, Sovereignty, Executive, Judicial, Legislative';
                            temp[key]['Answer'] = '';
                            break;
                    default:
                        temp[key] = '';
                        break;
                }
            });
            setTravelogueAnswers({ progress: progress_temp, answers: temp });
        }
        
        RegenerateTextBoxes(temp)
        setCurrentText(temp[currentPhasePos]);
        setPhases(phases);
    }, [phases, currentPhase, currentPhasePos, answers, progress]);

    const answer = (value, index) => {
        if (index) {
            answers[currentPhasePos][index] = value;
        }
        else {
            answers[currentPhasePos] = value;
        }
        setCurrentText(value);
    
        CheckAnswers(answers[currentPhasePos]);
    }


    const CheckAnswers = (answer) => {

        if (!answer) {
            answer = travelogueAnswers.answers[currentPhasePos];
        }

        let errormessage = null;

        switch (currentPhase.category) {
            case 'three_type':
                errormessage = CheckForSentences(answer, (currentPhase.sources.length * 3), true, currentPhase.sources.length)
                break;
            case 'two_type':
                errormessage = CheckForSentences(answer, (currentPhase.sources.length * 2), true, currentPhase.sources.length)
                break;
            case 'letter':
            case 'story':
                errormessage = CheckForSentences(answer, 3, false, currentPhase.sources.length);
                break;
            case 'phrase':
                errormessage = CheckForSentences(answer, 2, true, currentPhase.sources.length);
                break;
            case 'details':
                errormessage = CheckForSentences(answer, 2, true, currentPhase.sources.length);
                break;
            case 'poem':
                errormessage = CheckPoem(answer);
                break;
            case 'traits':
                errormessage = CheckTraits(answer);
            case 'questions':
                errormessage = CheckForSentences(answer, 0, false, currentPhase.sources.length);
                break;
            case 'teach_me_more':
                errormessage = CheckForSentences(answer.Reason, 1, false, Math.floor(answer.Source.split("   ").length / 2));
                break;
            case 'events':
                errormessage = CheckForSentences(answer, 2, false, currentPhase.sources.length);
                break;
            case 'word_bank':
                errormessage = CheckForSentences(answer.Answer, 1, false, currentPhase.sources.length);
                break;
            case 'snapshot':
                errormessage = CheckForSentences(answer.Answer, 3, false, 1);
                break;
            default:
                break;
        }

        const result = errormessage ? false : true

        togglePhaseButton(result);
        return result;
    }

    const RegenerateTextBoxes = (answers) => {
        let temp = [];
        if (typeof answers[currentPhasePos] === 'object') {
            Object.keys(answers[currentPhasePos]).map((value, index) => {
                temp.push(
                    <div style={{ width: '-webkit-fill-available', flexDirection: 'column', display: 'flex' }}>
                        <label>{value}{currentPhase.category == 'poem' ? '' : 's'}</label>
                        {
                            value == 'Source' ?
                                (answers[currentPhasePos][value]).split("   ").map((source) => {
                                    if (source.includes('http')) {
                                        return (
                                            <img style={{
                                                width: 'auto', maxWidth: '75%',
                                            }}
                                                key={source} src={source}></img>
                                        )
                                    }
                                    return (
                                        <p style={{
                                            width: 'auto', maxWidth: '75%', maxHeight: '200px', overflowY: 'scroll'
                                        }}>{source}</p>
                                    )
                                })
                                :
                                <textarea style={{ height: '80%', width: '13.5vw' }} onChange={(e) => answer(e.target.value, value)} defaultValue={answers[currentPhasePos][value]} key={value + index}></textarea>
                        }
                    </div>
                )
            })
        }
        else {
            if(currentPhasePos == 0){
                temp.push(
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <label>Welcome to your Travelogue</label>
                        <p id='first-phase-text'>Click the "Next Phase" button to start the quest!</p>
                    </div>
                )  
            } else{
                temp.push(
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <label>Travelogue</label>
                        <textarea style={{ height: '80%' }} onChange={(e) => answer(e.target.value, null)} defaultValue={answers[currentPhasePos]}></textarea>
                    </div>
                )
            }
            
        }
        setTextBoxes(temp)
    }

    const CheckForSentences = (sentencesArray, minAmount, multiColumn, sourceCount) => {
        let temp = ''
        if (multiColumn) {
            Object.keys(sentencesArray).map((value, index) => {
                temp += sentencesArray[value];
            })
        }
        else {
            temp = sentencesArray;
        }

        const sentences = IndividualSentences(temp);

        const numberOfLineBreaks = (temp.match(/\n/g) || []).length;

        let errormessage = ''
        const uppercaseErrorCount = CheckPoperSentences(sentences);
        errormessage += uppercaseErrorCount ? '(' + uppercaseErrorCount + ') sentences do not start with a Capitol Letter.' : '';


        if (minAmount > 0 && sentences.length <= minAmount) {
            errormessage += "\n Need (" + ((minAmount) - sentences.length + 1) + ') more valid sentences.'
        }
        else if (minAmount == -1) {
            errormessage += "\n You have (" + (minAmount) + ') improper sentences.'
        }

        if (sourceCount > (numberOfLineBreaks) && sentences.length >= numberOfLineBreaks) {
            errormessage += "\n You have (" + Math.ceil(sourceCount - Math.ceil(numberOfLineBreaks / 2)) + ') sources left to write about.'
        }
        setError(errormessage);
        return errormessage;
    }

    const CheckPoperSentences = (sentences) => {
        let uppercaseErrorCount = 0

        for (let x = 0; x < sentences.length - 1; x++) {
            const StartingCharacter = sentences[x][0];
            if (!StartingCharacter) {
                uppercaseErrorCount++;
            }
            else if (StartingCharacter !== StartingCharacter.toUpperCase()) {
                uppercaseErrorCount++;
            }
        }
        return uppercaseErrorCount;
    }

    const CheckPoem = (input) => {
        const sentences = IndividualSentences(input.Poem);
        const wordsCount = IndividualWords(input.Poem);
        let useWords = IndividualWords(input.Words);
        let nonUsedWords = [];

        let errormessage = '';
        let uppercaseErrorCount = 0;

        for (const x in wordsCount) {
            wordsCount[x] = wordsCount[x].toLowerCase();
        }


        for (let x = 0; x < useWords.length; x++) {
            const word = useWords[x].toLowerCase().replace(" ", "")
            if (word && !wordsCount.includes(word)) {
                nonUsedWords.push(useWords[x].replace(" ", ""))
            }
        }


        for (let x = 0; x < sentences.length - 1; x++) {
            const StartingCharacter = sentences[x][0];
            if (!StartingCharacter) {
                uppercaseErrorCount++;
            }
            else if (StartingCharacter !== StartingCharacter.toUpperCase()) {
                uppercaseErrorCount++;
            }
        }
        errormessage += uppercaseErrorCount ? '(' + uppercaseErrorCount + ') sentences do not start with a Capitol Letter.' : '';

        if (wordsCount.length < 20) {
            errormessage += "\n You need at least " + (20 - wordsCount.length) + " words."
        }

        if (nonUsedWords.length > 0) {
            errormessage += "\n These words are missing: "
            for (const x in nonUsedWords) {
                errormessage += nonUsedWords[x] + (x == nonUsedWords.length - 1 ? '' : ', ');
            }
        }



        setError(errormessage);
        return errormessage;
    }

    const CheckTraits = (input) => {
        const sentences = IndividualSentences(input);
        const wordCount = IndividualWords(input);


        let errormessage = '';
        let uppercaseErrorCount = 0;

        for (let x = 0; x < sentences.length - 1; x++) {
            const StartingCharacter = sentences[x][0];
            if (!StartingCharacter) {
                uppercaseErrorCount++;
            }
            else if (StartingCharacter !== StartingCharacter.toUpperCase()) {
                uppercaseErrorCount++;
            }
        }
        errormessage += uppercaseErrorCount ? '(' + uppercaseErrorCount + ') sentences do not start with a Capitol Letter.' : '';

        if (wordCount.length < 5) {
            errormessage += `\n You need ${5 - wordCount.length} more words or sentences.`
        }

        setError(errormessage);
        return errormessage;
    }

    const IndividualWords = (text) => {
        const wordsCount = text.split(/[\s\p{P}]+/u);
        let temp = [];
        for (const x in wordsCount) {
            if (wordsCount[x]) {
                temp.push(wordsCount[x])
            }
        }
        return temp;
    }

    const IndividualSentences = (text) => {
        const sentences = text.split('.');
        return sentences;
    }

    const TryMoveNextPhase = () => {
        userActions.UpdateTravelogue(token, quest, { answers: answers, progress: progress }, (data) => {
            
        })

        const result = CheckAnswers(null);
        if (result) {
            MovetoNextPhase(error);
            setPhaseButton('hidden');
        }
    }

    const togglePhaseButton = (display) => {
        setPhaseButton(display ? '' : 'hidden');
    }


    // console.log(travelogueAnswers)

    return (
        <Fragment>
            <div className={'full'} style={{ backgroundColor: '#FFE7DC', display: 'flex', position: 'relative' }}>
                <div id='inner_container' className='travelogue-shadow' style={{ margin: '18% 2% 0% 2%', display: 'flex', width: '100%', height: '90%', backgroundColor: '#873B0C', borderRadius: '0.5em' }}>
                    <h2 style={{ position: 'absolute', margin: '-10% 0% 0% 0%', color: 'red', fontWeight: 'bold', fontSize: '1.1vi' }}>{error}</h2>

                    <div className='travelogue-tabs'>
                        {Object.keys(content).map((value, key) => {
                            const place = Number(value.split('_')[1]);
                            if (place == 1 || progress[place - 1] == 1 || progress[place - 2] == 1 || progress[place + 1] == 1) {
                                return (
                                    <div id={value} key={value} className={`travelogue-tab${progress[place - 1] == 1 ? '2' : ''} ${key == currentPhasePos ? 'tab-active' : ''}`} style={{ transform: `translateX(${(key * 2) + 4}px)` }} onClick={(e) => { goToPhase(content[value], key); setError('');  }}>
                                        <p style={{ margin: 'auto' }}>{key + 1}</p>
                                    </div>
                                )
                            }
                        }
                        )}
                    </div>
                    <div className='travelogue-textarea-shadow' style={{ display: 'flex', margin: 'auto 5%', height: '95%', width: '97%', padding: '2%', fontSize: '1.3vi', color: 'black' }}>
                        {textBoxes}
                    </div>
                </div>
                <button className={`next-phase ${displayPhaseButton}`} onClick={() => TryMoveNextPhase()}>Next Phase</button>
            </div>

        </Fragment>
    )
};

function mapState(state) {
    const { session } = state;
    return { session };
}

const actionCreators = {
};

const connection = connect(mapState, actionCreators)(Travelogue);
export { connection as Travelogue };